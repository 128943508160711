import { analyticsChart } from "../util/charts";
import { getWeeklyAverageHotelPrices } from "../util/hotelData";
import { showSurveyResults } from "../util/surveyData";

document.addEventListener("turbolinks:load", function() {
  // if portal page
  if (document.getElementById("portal")) {
    let trailingDays = 7;

    const params = new URLSearchParams(window.location.search);
    if (params.get("days") && !isNaN(parseInt(params.get("days")))) {
      trailingDays = params.get("days");
    }

    getWeeklyAverageHotelPrices("hotel-prices-chart", true);
    showSurveyResults(
      {
        ageRanges: "age-ranges-chart",
        residences: "residences-chart",
        showsPerYear: "shows-per-year-chart",
        covidConcern: "covid-concern-score",
        isFirstShow: "is-first-show-chart",
      },
      trailingDays,
      true
    );

    analyticsChart("seven-day-analytics", true, true, 30);

    handleToggle();

    window.addEventListener("resize", handleEnvironmentHeight);

    setPortalButtonListeners();

    fixBulletinBoardStyles();
  }
});

function fixBulletinBoardStyles() {
  document
    .getElementById("bulletin-board")
    ?.querySelectorAll("[style]")
    .forEach((elem) => {
      elem.style.color = "";
      elem.style.backgroundColor = "";
    });
}

function setPortalButtonListeners() {
  const card = document.querySelector(".live-board__card");
  document.querySelector("button.more")?.addEventListener("click", () => {
    card.classList.add("open");
  });
  document.querySelector("button.less")?.addEventListener("click", () => {
    card.classList.remove("open");
  });
}

function handleToggle() {
  const slider = document.getElementById("portal-toggle");
  if (!slider) return;

  slider.addEventListener("slider-change", (event) => {

    const listingsDiv = document.getElementById("listings");
    const environmentDiv = document.getElementById("environment");
    const historicalDiv = document.getElementById("historical");

    switch (event.detail.value) {
      case 'Listings':
        listingsDiv.style.display = "block";
        environmentDiv.style.display = "none";
        historicalDiv.style.display = "none";
        break;
      case 'Environment':
        listingsDiv.style.display = "none";
        historicalDiv.style.display = "none";
        environmentDiv.style.display = "block";
        setTimeout(() => handleEnvironmentHeight(), 200);
        break;
      case 'Historical':
        listingsDiv.style.display = "none";
        environmentDiv.style.display = "none";
        historicalDiv.style.display = "block";
        break;
    }
  });
}

function handleEnvironmentHeight() {
  document
    .getElementById("environment")
    .querySelectorAll(".column.adjustable")
    .forEach((column) => {
      const { height } = column.getBoundingClientRect();

      if (column.classList.contains("fit-height")) {
        if (height <= window.innerHeight - 160) {
          column.classList.remove("fit-height");
        }
      } else {
        if (height > window.innerHeight - 160) {
          column.classList.add("fit-height");
        }
      }

      column.querySelector("button.more")?.addEventListener("click", () => {
        column.classList.remove("fit-height");
        column.classList.add("expanded");
      });

      column.querySelector("button.less")?.addEventListener("click", () => {
        column.classList.add("fit-height");
        column.classList.remove("expanded");
      });
    });
}
