import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels

export const chartColors = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  // 'rgba(0, 84, 219, 0.5)',
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
];
export const getChartColor = (i) => {
  return chartColors[i % chartColors.length];
};
export const borderColors = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  // 'rgba(0, 84, 219)',
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

export const bigChartColors = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#651067",
];
export const getBigChartColor = (i) => {
  return bigChartColors[i % bigChartColors.length];
};

export const getBorderColor = (i) => {
  return borderColors[i % borderColors.length];
};

export const buildTimestamp = (date) => {
  const timestamp = `${date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

  return timestamp;
};

window.chartUtils = {
  chartColors,
  getChartColor,
  borderColors,
  bigChartColors,
  getBigChartColor,
  getBorderColor,
  buildTimestamp,
}

export const createBarChart = (
  elementId,
  columnLabels,
  dataLabel,
  chartData,
  barLabelMutation = (label) => label.toString(),
  isDarkTheme = false,
  horizontal = false
) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  const chart = new Chart(ctx, {
    type: "bar",
    data: {
      labels: columnLabels,
      datasets: [
        {
          label: dataLabel,
          data: chartData,
          backgroundColor: chartColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    },
  });

  const showNumbers = () => {
    if (!horizontal) {
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
    } else {
      ctx.textBaseline = "middle";
    }
    ctx.fillStyle = isDarkTheme ? "#f0f0f0" : "#333";

    // Loop through each data in the datasets
    chart.data.datasets.forEach((dataset, i) => {
      var meta = chart.getDatasetMeta(i);
      meta.data.forEach(function(bar, index) {
        var data = barLabelMutation(dataset.data[index]);
        ctx.fillText(data, bar.x, bar.y);
      });
    });
  };

  chart.options = {
    responsive: true,
    indexAxis: horizontal ? "y" : "x",
    events: [],
    tooltips: {
      mode: "point",
    },
    animation: {
      onProgress: showNumbers,
      onComplete: showNumbers,
    },
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
      x: {
        ticks: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
    },
  };

  // Show the chart
  element.innerHTML = "";
  element.appendChild(canvas);
};

export const createLineChart = (
  elementId,
  columnLabels,
  dataLabel,
  chartData,
  barLabelMutation = (label) => label.toString(),
  isDarkTheme = false,
  horizontal = false,
) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  const chart = new Chart(ctx, {
    type: "line",
    data: {
      labels: columnLabels,
      datasets: [
        {
          label: dataLabel,
          data: chartData,
          backgroundColor: chartColors,
          // borderColor: borderColors,
          borderColor: isDarkTheme ? ["#ccc"] : undefined,
          borderWidth: 1,
        },
      ],
    },
  });

  const showNumbers = () => {
    if (!horizontal) {
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
    } else {
      ctx.textBaseline = "middle";
    }
    ctx.fillStyle = isDarkTheme ? "#f0f0f0" : "#333";

    // Loop through each data in the datasets
    chart.data.datasets.forEach((dataset, i) => {
      var meta = chart.getDatasetMeta(i);
      meta.data.forEach(function(bar, index) {
        var data = barLabelMutation(dataset.data[index]);
        ctx.fillText(data, bar.x, bar.y - 5);
      });
    });
  };

  chart.options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: horizontal ? "y" : "x",
    events: [],
    tooltips: {
      mode: "point",
    },
    animation: {
      onProgress: showNumbers,
      onComplete: showNumbers,
    },
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        max: chartData.reduce((max, item) => {
          const rounded = Math.ceil(item / 50) * 50 + 50;
          if (rounded > max) {
            return rounded;
          }
          return max;
        }, 0),
        ticks: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
      x: {
        ticks: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
    },
  };

  // Show the chart
  element.innerHTML = "";
  element.appendChild(canvas);
};

export const createDoughnutChart = (
  elementId,
  segmentLabels,
  dataLabel,
  chartData,
  isDarkTheme = false
) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  const chart = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: segmentLabels,
      datasets: [
        {
          label: dataLabel,
          data: chartData,
          backgroundColor: chartColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    },
  });

  chart.options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue}%`;
          },
        },
      },
    },
  };

  // Show the chart
  element.innerHTML = "";
  element.appendChild(canvas);
};

export const createPieChart = (
  elementId,
  segmentLabels,
  dataLabel,
  chartData,
  isDarkTheme = false,
  legendPosition = "top"
) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  const chart = new Chart(ctx, {
    type: "pie",
    data: {
      labels: segmentLabels,
      datasets: [
        {
          label: dataLabel,
          data: chartData,
          backgroundColor: chartColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    },
  });

  chart.options = {
    responsive: true,
    plugins: {
      legend: {
        position: legendPosition,
        labels: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue}%`;
          },
        },
      },
    },
  };

  // Show the chart
  element.innerHTML = "";
  element.appendChild(canvas);
};

export function analyticsChart(
  id,
  displayAsPercent,
  fixedHeight,
  thickness = 17
) {
  const element = document.getElementById(id);
  if (!element) return;
  const data = JSON.parse(element.dataset.chartData);
  const countryCodeMap = element.dataset.countryCodeMap
    ? JSON.parse(element.dataset.countryCodeMap)
    : {};

  const columnLabels = Object.keys(data);
  const chartData = Object.values(data);
  const isDarkTheme = true;
  const horizontal = true;

  if (displayAsPercent) {
    const total = chartData.reduce((total, number) => total + number, 0);
    for (let i = 0; i < chartData.length; i++) {
      chartData[i] = ((chartData[i] / total) * 100).toFixed(2);
    }
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  canvas.height = chartData.length * 15;

  const chart = new Chart(ctx, {
    type: "bar",
    data: {
      labels: columnLabels,
      datasets: [
        {
          data: chartData,
          backgroundColor: ["#0054db"],
          borderColor: ["#194287"],
          borderWidth: 2,
          barThickness: thickness - 2,
        },
      ],
    },
    plugins: [
      {
        afterDraw: (chart) => {
          var ctx = chart.ctx;
          var xAxis = chart.scales["x"];
          var yAxis = chart.scales["y"];
          yAxis.ticks.forEach((value, index) => {
            var y = yAxis.getPixelForTick(index);
            const codeIndex = Object.values(countryCodeMap).indexOf(
              value.label
            );
            if (codeIndex === -1) return;
            const code = Object.keys(countryCodeMap)[codeIndex]?.toLowerCase();
            var image = new Image();
            image.src = `https://flagcdn.com/w20/${code}.png`;
            ctx.drawImage(image, xAxis.left - 22, y - 5);
          });
        },
      },
    ],
  });

  chart.options = {
    maintainAspectRatio: !fixedHeight,
    responsive: true,
    indexAxis: horizontal ? "y" : "x",
    tooltips: {
      mode: "point",
    },
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue}${displayAsPercent ? "%" : ""}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          padding: Object.keys(countryCodeMap).length ? 20 : 5,
          autoSkip: false,
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
      x: {
        ticks: {
          color: isDarkTheme ? "#f0f0f0" : "#333",
        },
      },
    },
  };

  // Show the chart
  element.innerHTML = "";
  element.appendChild(canvas);
  chart.update();

  return chart;
}

export const renderChartToScreen = (elementId, config) => {
  const element = document.getElementById(elementId);
  if (!element) return;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas not supported");
  }

  const chart = new Chart(ctx, config);

  element.innerHTML = "";
  element.classList.add("active");
  element.appendChild(canvas);

  return chart;
};
